.hero-container {

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #FFF3C8;
  font-size: 48px;
  margin-top: -10px;
}

.hero-container > h2 {
  margin-top: 8px;
  color: #FFF3C8;
  font-size: 24px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns .btn {
  margin: 6px;
}
.hero-input-tit {
  padding: 4px 10px;
  margin-right: 50px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

.hero-textarea-tit {
  color: #fff;
  padding: 4px 10px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

.hero-input {
  padding: 5px 10px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #fff;
}

.hero-input-long {
  padding: 5px 10px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
  min-width: 600px;
  border: 1px solid #fff;
}

.hero-textarea {
  padding: 4px 10px;
  border-radius: 2px;
  margin-left: 140px;
  outline: none;
  border: none;
  font-size: 16px;
  margin-bottom: 10px;
  min-height: 150px;
  min-width: 600px;
  border: 1px solid #fff;
}
.input-areas {
  margin-top: 20px;
}
.input-areas > p{
  margin-top: 8px;
  color: #fff;
  font-size: 16px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 75px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 16px;
  }

  .btn {
    width: 100%;
  }
}

/* register.html css*/
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  height: 100vh;
  width: 100%;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}
.show-btn-text{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
.show-btn-value{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 20%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

.show-btn{
  background: #fff;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #3498db;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.show-btn, .container{
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]{
  display: none;
}
.container{
  display: none;
  background: #fff;
  width: 410px;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
}

.container-new{
    display: none;
    background: #fff;
    width: 410px;
    padding: 30px;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
  }

#show:checked ~ .container{
  display: block;
}

.container .close-btn{
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.container .close-btn:hover{
  color: #3498db;
}
.container .text{
  font-size: 35px;
  font-weight: 600;
  text-align: center;
}
.container form{
  margin-top: -20px;
}
.container form .data{
  height: 45px;
  width: 100%;
  margin: 40px 0;
}
.container form .data-hidden{
    display: none;
}
form .data label{
  font-size: 18px;
}
form .data input{
  height: 100%;
  width: 100%;
  padding-left: 10px;
  font-size: 17px;
  border: 1px solid silver;
}
form .data input:focus{
  border-color: #3498db;
  border-bottom-width: 2px;
}
form .forgot-pass{
  margin-top: -8px;
}
form .forgot-pass a{
  color: #3498db;
  text-decoration: none;
}
form .forgot-pass a:hover{
  text-decoration: underline;
}
form .btn{
  margin: 30px 0;
  height: 45px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
form .btn .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  z-index: -1;
  background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
  transition: all 0.4s;
}
.btn:hover .inner{
  left: 0;
}
form .btn button{
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
form .signup-link{
  text-align: center;
}
form .signup-link a{
  color: #3498db;
  text-decoration: none;
}
form .signup-link a:hover{
  text-decoration: underline;
}




.show-btn-new{
    background: #fff;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    color: #7a34db;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  
  .show-btn-new, .container-new{
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  }
  
  input[type="checkbox"]{
    display: none;
  }
  .container-new{
    display: none;
    background: #fff;
    width: 410px;
    padding: 30px;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
  }
  
  .container-new{
      display: none;
      background: #fff;
      width: 410px;
      padding: 30px;
      box-shadow: 0 0 8px rgba(0,0,0,0.1);
    }
  
  #show-new:checked ~ .container-new{
    display: block;
  }
  
  .container-new .close-btn{
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 18px;
    cursor: pointer;
  }
  .container-new .close-btn:hover{
    color: #3498db;
  }
  .container-new .text{
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
  .container-new form{
    margin-top: -20px;
  }
  .container-new form .data{
    height: 45px;
    width: 100%;
    margin: 40px 0;
  }
  .container-new form .data-hidden{
    display: none;
  }

  .show-btn-text-20{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 30%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
.show-btn-value-20{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .show-btn-text-30{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 38%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
.show-btn-value-30{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 38%;
    left: 60%;
    transform: translate(-50%, -50%);
  }

  .show-btn-text-40{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 43%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
.show-btn-value-40{
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 500;
    color: #f7f8f9;
    cursor: pointer;
    position: absolute;
    top: 43%;
    left: 60%;
    transform: translate(-50%, -50%);
  }